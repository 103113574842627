<template>
	<div class="page page-sign-up">
		<h3>Sign Up</h3>
		<div v-if="loading">Loading...</div>
		<div v-else>
			<div v-if="!confirmPassword">
				<form @submit.prevent="signUp">
					<div>
						<TextInput label="Email" v-model="email" />
					</div>
					<div>
						<TextInput
							label="Password"
							type="password"
							v-model="password"
						/>
					</div>
					<button>Submit</button>
				</form>
				<button class="facebook" @click.prevent="signInFacebook">
					Sign up with Facebook
				</button>
			</div>
			<div v-else>
				<form @submit.prevent="confirmSignUp">
					<p>Please check your email for a verification code.</p>
					<div>
						<label for="code">Code</label>
						<input type="text" v-model="code" id="code" />
					</div>
					<button>Confirm</button>
				</form>
			</div>

			<div>Already have an account?</div>
			<div>
				<router-link class="btn text" to="/">Sign in</router-link>
			</div>

			<div>{{ error.message }}</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex";
import TextInput from "../components/inputs/TextInput";

export default {
	name: "SignUp",
	components: { TextInput },
	data: () => ({
		email: "",
		password: "",
		confirmPassword: false,
		code: "",
		error: "",
		loading: false,
	}),
	methods: {
		...mapActions({
			signInAction: "auth/signIn",
			signUpAction: "auth/signUp",
			confirmSignUpAction: "auth/confirmSignUp",
		}),
		async signUp() {
			this.error = "";
			this.loading = true;
			try {
				await this.signUpAction({
					username: this.email,
					password: this.password,
					email: this.email,
				});

				this.confirmPassword = true;
			} catch (err) {
				this.error = err;
			}
			this.loading = false;
		},
		async confirmSignUp() {
			if (!this.email || !this.code) return;

			this.error = "";
			this.loading = true;
			try {
				await this.confirmSignUpAction({
					username: this.email,
					code: this.code,
				});
				await this.signInAction({
					username: this.email,
					password: this.password,
				});
				this.$router.push("/board");
			} catch (err) {
				this.error = err;
			}
			this.loading = false;
		},
	},
};
</script>
